import { keyframes, styled } from "@mui/system";
import React, { forwardRef, useRef } from "react";
import IconButton from "../../../../../../../../../IconButton";
import ClipIcon from "../../../../../../../../../Icons/ClipIcon";

const zoomIn = keyframes`
  from {
    transform: scale(0,0);
  }
  to {
    transform: scale(1,1);
  }
`;
const RootDiv = styled("div")(props => ({
  display: "flex",
  flexDirection: "column-reverse",
  padding: 4,
  alignSelf: "center"
}));

const MenuItem = styled("div", {
  shouldForwardProp: prop => prop !== "duration"
})(props => ({
  animation: `${zoomIn} ${props.duration || "0.5s"}`,
  padding: `4px 0`
}));

const Menus = styled("div")({
  display: "inline-block",
  position: "relative"
});

const Div = styled("div")(({ open }) => ({
  display: open ? "flex" : "none",
  position: "absolute",
  bottom: 4,
  flexDirection: "column-reverse"
}));

const Menu = forwardRef(
  (
    {
      open = false,
      delay = 0.2,
      children,
      onClick = () => {},
      disabled = false
    },
    ref
  ) => {
    const buttonRef = useRef();
    console.log(ref, open);

    return (
      <RootDiv ref={ref}>
        <IconButton
          onClick={onClick}
          ref={buttonRef}
          color={open ? "#40c4ff" : null}
          style={{ marginLeft: 6 }}
          disabled={disabled}
        >
          <ClipIcon />
        </IconButton>

        <Menus>
          <Div open={open}>
            {React.Children.map(children, (child, index) => {
              return (
                <MenuItem key={index} duration={`${(index + 1) * delay}s`}>
                  {child}
                </MenuItem>
              );
            })}
          </Div>
        </Menus>
      </RootDiv>
    );
  }
);
export default Menu;
