import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import React from "react";
import Button from "../../../../../Button";
import AddIcon from "../../../../../Icons/AddIcon";

const RootDiv = styled("div")({
  padding: 12,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

function ChannelListHeader({ title, onClick = () => {} }) {
  return (
    <RootDiv>
      <Typography variant={"h3"}>WhatsApp</Typography>
      <Button onClick={onClick}>
        <AddIcon />
        <Typography color="inherit">Search/New</Typography>
      </Button>
    </RootDiv>
  );
}

export default ChannelListHeader;
