import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

const RootDiv = styled("div")(({ open }) => ({
  position: "relative",
  width: "fit-content",
  "&:hover": {
    cursor: "pointer"
  },
  color: "rgba(0, 0, 0, 0.38)"
}));

const Label = styled("div")(({ theme }) => ({
  display: "flex",
  // backgroundColor: "red",
  // width: 100,
  justifyContent: "space-between",
  padding: 4
  // "&:hover": {
  //   backgroundColor: theme.palette.select.main
  // }
}));

const Item = styled("div")(({ theme }) => ({
  padding: "4px",
  "&:hover": {
    backgroundColor: theme.palette.select.main
  }
}));

const Select = ({ children, onChange }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const ref = useRef();
  const labelRef = useRef();

  useEffect(() => {
    const onMouseDown = e => {
      const ret = ref.current.contains(e.target);
      const ret2 = labelRef.current.contains(e.target);
      console.log(ret);
      if (!ret && !ret2) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("mousedown", onMouseDown);
    }

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, [open]);

  return (
    <RootDiv>
      <Label
        onClick={() => {
          setOpen(old => !old);
        }}
        ref={labelRef}
      >
        {children[index]}
        <div style={{ fontSize: 12, alignSelf: "center", padding: "0 4px" }}>
          {open ? "▲" : "▼"}
        </div>
      </Label>
      {open && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            width: 100,

            border: "1px solid #b0bec5"
          }}
          ref={ref}
        >
          {React.Children.map(children, (child, childIndex) => {
            console.log(child);
            const { value } = child.props;
            if (index === childIndex) return null;
            return (
              <Item
                onClick={() => {
                  onChange(value);
                  setOpen(false);
                  setIndex(childIndex);
                }}
              >
                {child}
              </Item>
            );
          })}
        </div>
      )}
    </RootDiv>
  );
};

export default Select;
