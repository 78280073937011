import "./locales/TimeLocale/en/en";
import "./locales/TimeLocale/eg/eg";
import "./locales/TimeLocale/en-eg/en-eg";
import "./locales/TimeLocale/ru/ru";
import "./locales/TimeLocale/en-ru/en-ru";
import "./locales/TimeLocale/vi/vi";
import "./locales/TimeLocale/en-vi/en-vi";

import { createTheme, styled, ThemeProvider } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ChatProvider from "./components/ChatProvider";
import ChatView from "./components/ChatView";
import palette from "./theme/palette";
import typography, { ratio } from "./theme/typohgraphy";
import packageJson from "../package.json";
import { IntlProvider } from "react-intl";
import en from "./lang/en.json";
import eg from "./lang/eg.json";
import ru from "./lang/ru.json";
import eneg from "./lang/en-eg.json";
import envi from "./lang/en-vi.json";
import enru from "./lang/en-ru.json";
import { getMomentLocale } from "./utils/datetime";

const customTheme = ratio => {
  return createTheme({
    palette,
    typography: typography(ratio)
  });
};

async function VerifyWhatsAppApiKey(apiKey, subject, to) {
  return new Promise(async (resolve, reject) => {
    axios({
      url: "https://im.edna.io/api/im-subject/by-apikey",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": apiKey
      },
      body: {
        imType: "WHATSAPP"
      }
    })
      .then(res => {
        console.log(res);
        const { status, data } = res;
        if (status !== 200) {
          resolve(status);
        }

        const fIndex = data.findIndex(el => el.subject === subject && el.phone === to);
        if (fIndex === -1) resolve("Miss Match");

        resolve();
      })
      .catch(e => {
        console.log(e);
        resolve(e);
      });
  });
}

function BackGround({ onReceived = () => {} }) {
  const onReceivedRef = useRef(onReceived);
  const toRef = useRef();
  const subjectRef = useRef();
  const apiKeyRef = useRef();
  const tokenRef = useRef();
  const hospitalIdRef = useRef();
  const hospitalNameRef = useRef();
  const langRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    const onMessageReceivedFromIframe = e => {
      console.log("**************", e);
      if (e.data?.message === "chat") {
        if (
          e.data?.type &&
          e.data?.apiKey &&
          e.data?.subject &&
          e.data?.to &&
          e.data?.token &&
          e.data?.hospitalId &&
          e.data?.name &&
          e.data?.lang
        ) {
          if (onReceivedRef.current)
            onReceivedRef.current({
              type: e.data.type,
              apiKey: e.data.apiKey,
              subject: e.data.subject,
              to: e.data.to,
              token: e.data.token,
              name: e.data.name,
              hospitalId: e.data.hospitalId,
              language: e.data.lang
            });
          navigate("/whatsapp");
        } else {
          alert("error");
        }
      }
    };

    window.addEventListener("message", onMessageReceivedFromIframe);
    return () => {
      window.removeEventListener("message", onMessageReceivedFromIframe);
    };
  }, [navigate]);

  useEffect(() => {
    if (toRef.current) {
      toRef.current.value = "82313799577";
    }
    if (subjectRef.current) {
      subjectRef.current.value = "Vatech_WA";
    }
    if (apiKeyRef.current) {
      apiKeyRef.current.value = "6ed7b847-0a94-4121-a252-a18ff13dc317";
    }
    if (tokenRef.current) {
      tokenRef.current.value =
        "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBhMXhBUzRoVWNqaWtMaDJQRV9Bc3NweC1uSkNDX2Iyaktucm16c3h2TkUifQ.eyJqdGkiOiJDUElLZ1FKeHQzRjRpU25nMXdybVoiLCJzdWIiOiI3MGVmMmVkNS00NWJjLTQ2NjgtODA5MC0wMTFhMTEzYThiZmEiLCJpYXQiOjE2Mzk2MTY3OTgsImV4cCI6MTYzOTY1OTk5OCwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSIsImlzcyI6Imh0dHBzOi8vY3ZhdXRoLmRldmVsb3AudnNtYXJ0MDAuY29tIiwiYXVkIjoiY3Zib2FyZF9kZXZlbG9wIiwiaXNzdWVySWQiOiJDbGV2ZXIifQ.996wtSc38jNyzfunzbxhJPFXQJik9zpTHq4Hbpxblghx11zIkdBcRhozroitLoLjOJkpE_wbg6GHtToKxixBOg/eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBhMXhBUzRoVWNqaWtMaDJQRV9Bc3NweC1uSkNDX2Iyaktucm16c3h2TkUifQ.eyJzdWIiOiI3MGVmMmVkNS00NWJjLTQ2NjgtODA5MC0wMTFhMTEzYThiZmEiLCJob3NwaXRhbElkIjoidmF0ZWNoIiwibG9naW5JZCI6InVzZXIwMCIsIm5hbWUiOiJTaGluIiwiaXNzdWVySWQiOiJDbGV2ZXIiLCJhdF9oYXNoIjoiVXZnTjZJUVFfUnVSRUNQM3pSWk15ZyIsImF1ZCI6ImN2Ym9hcmRfZGV2ZWxvcCIsImV4cCI6MTYzOTY1OTk5OCwiaWF0IjoxNjM5NjE2Nzk4LCJpc3MiOiJodHRwczovL2N2YXV0aC5kZXZlbG9wLnZzbWFydDAwLmNvbSJ9.S66GLbaZt4Z7OZsYcvfUOn_UZs4NmgUkrVJh0O4W3m-oeK86o35m2gbGAcAoFPxxPJjmyDKQgqoOGW-uBqOdBg";
    }
    if (hospitalNameRef.current) {
      hospitalNameRef.current.value = "vatech";
    }
    if (hospitalIdRef.current) {
      hospitalIdRef.current.value = "vatech";
    }
    if (langRef.current) {
      langRef.current.value = "ru";
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 400
        }}
      >
        <div style={{ padding: 8 }}>
          <select
            name="score"
            id="select-id"
            style={{
              padding: 8,
              width: "100%",
              boxSizing: "border-box"
            }}
          >
            <option value="Whatsapp">Whatsapp</option>
          </select>
        </div>

        <Input placeholder="to" ref={toRef}></Input>

        <Input placeholder="subject" ref={subjectRef}></Input>

        <Input placeholder="apiKey" ref={apiKeyRef}></Input>

        <Input placeholder="token" ref={tokenRef}></Input>

        <Input placeholder="hospitalName" ref={hospitalNameRef}></Input>

        <Input placeholder="hospitalId" ref={hospitalIdRef}></Input>

        <Input placeholder="lang" ref={langRef}></Input>

        <button
          style={{ margin: 8, padding: 8 }}
          onClick={async () => {
            const error = await VerifyWhatsAppApiKey(
              apiKeyRef.current.value,
              subjectRef.current.value,
              toRef.current.value
            );

            sessionStorage.setItem("wa_apiKey", apiKeyRef.current.value);
            sessionStorage.setItem("wa_subject", subjectRef.current.value);
            sessionStorage.setItem("wa_to", toRef.current.value);
            sessionStorage.setItem("wa_token", tokenRef.current.value);
            sessionStorage.setItem("wa_hospitalId", hospitalIdRef.current.value);
            sessionStorage.setItem("wa_name", hospitalNameRef.current.value);
            sessionStorage.setItem("wa_lang", langRef.current.value);

            if (error) {
              alert(JSON.stringify(error));
            } else {
              onReceivedRef.current({
                type: "whatsapp",
                to: toRef.current.value,
                subject: subjectRef.current.value,
                apiKey: apiKeyRef.current.value,
                token: tokenRef.current.value,
                hospitalId: hospitalIdRef.current.value,
                name: hospitalNameRef.current.value,
                lang: langRef.current.value
              });

              navigate("/whatsapp");
            }
          }}
        >
          Button
        </button>
      </div>
    </div>
  );
}

function WaitForSetting({ onReceived = () => {} }) {
  const onReceivedRef = useRef(onReceived);
  let navigate = useNavigate();

  useEffect(() => {
    const onMessageReceivedFromIframe = e => {
      console.log("**************", e);
      if (e.data?.message === "chat") {
        if (
          e.data?.type &&
          e.data?.apiKey &&
          e.data?.subject &&
          e.data?.to &&
          e.data?.token &&
          e.data?.hospitalId &&
          e.data?.name &&
          e.data?.lang
        ) {
          if (onReceivedRef.current)
            onReceivedRef.current(
              {
                type: e.data.type,
                apiKey: e.data.apiKey,
                subject: e.data.subject,
                to: e.data.to,
                token: e.data.token,
                name: e.data.name,
                hospitalId: e.data.hospitalId
              },
              e.data.initChannel,
              e.data.lang,
              e.data.onlyRead
            );
          console.log("moment", getMomentLocale(e.data.lang));
          navigate("/whatsapp");
        } else {
          alert("error");
        }
      }
    };

    window.addEventListener("message", onMessageReceivedFromIframe);
    return () => {
      window.removeEventListener("message", onMessageReceivedFromIframe);
    };
  }, [navigate]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      Waiting For Setting
    </div>
  );
}
const Input = styled("input")({
  padding: 8,
  margin: 8
});

function parsingLang(lang) {
  if (lang === "en-eg") return "eneg";
  if (lang === "en-ru") return "enru";
  if (lang === "en-vi") return "envi";
  return lang;
}

function App() {
  const [initChannel, setInitChannel] = useState(null);
  const [info, setInfo] = useState();
  const [onlyRead, setOnlyRead] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");
  const messages = { en, ar: eg, eneg, ru, envi, enru }[parsingLang(lang)];
  console.log("lang local", localStorage.getItem("lang"));
  console.log("lang", lang);
  console.log("messages", messages);

  useEffect(() => {
    const to = sessionStorage.getItem("wa_to");
    const subject = sessionStorage.getItem("wa_subject");
    const apiKey = sessionStorage.getItem("wa_apiKey");
    const token = sessionStorage.getItem("wa_token");
    const name = sessionStorage.getItem("wa_name");
    const hospitalId = sessionStorage.getItem("wa_hospitalId");
    const language = sessionStorage.getItem("wa_lang");

    if (to && subject && apiKey && token && name && hospitalId)
      setInfo({
        type: "whatsapp",
        to,
        subject,
        apiKey,
        token,
        name,
        hospitalId
      });
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const reload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches
          .keys()
          .then(async function (names) {
            await Promise.all(names.map(name => caches.delete(name)));
            // for (let name of names) caches.delete(name);
            window.location.reload(true);
          })
          .catch(error => {
            console.log(error);
            window.location.reload(true);
          });
      }
    };

    fetch("/version.json", { cache: "no-cache" })
      .then(res => res.json())
      .then(meta => {
        const currentVersion = packageJson.config.version;
        const latestVersion = meta.version;
        // const currentCommit = process.env.REACT_APP_COMMIT;
        // const latestCommit = meta.commit;

        console.log("currentVersion", currentVersion);
        console.log("latestVersion", latestVersion);
        // console.log("currentCommit", currentCommit);
        // console.log("latestCommit", latestCommit);

        if (
          latestVersion !== currentVersion
          // || latestCommit !== currentCommit
        ) {
          console.info(`We have a new version - ${latestVersion}). Should force refresh`);
          reload();
        } else {
          console.info(
            `You already have the latest version - ${latestVersion}). No cache refresh needed.`
          );
        }
      });
  }, []);

  return (
    <BrowserRouter>
      <IntlProvider locale={lang} messages={messages}>
        <ChatProvider token={info?.token}>
          <ThemeProvider theme={customTheme(ratio(lang))}>
            <Routes>
              <Route
                path="/"
                element={<BackGround onReceived={e => setInfo(e)}>root</BackGround>}
              />
              <Route
                path="/clever"
                element={
                  <WaitForSetting
                    onReceived={(e, init, lang, read) => {
                      setInfo(e);
                      if (init) setInitChannel(init);
                      if (lang) {
                        console.log("localStroage set", lang);
                        localStorage.setItem("lang", lang);
                        setLang(lang);
                      }
                      if (read) setOnlyRead(read);
                    }}
                  >
                    root
                  </WaitForSetting>
                }
              />
              <Route
                path="/whatsapp"
                element={
                  <div
                    style={{
                      height: "calc(100vh)"
                    }}
                  >
                    <ChatView
                      info={info}
                      initChannel={initChannel}
                      type={"whatsapp"}
                      disabled={onlyRead}
                    ></ChatView>
                  </div>
                }
              />
            </Routes>
          </ThemeProvider>
        </ChatProvider>
      </IntlProvider>
    </BrowserRouter>
  );
}

export default App;
