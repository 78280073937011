import { gql, useMutation } from "@apollo/client";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { putObject, Target } from "../../../../../../../../../../utils/object";
import { FileType, MaxFileNameSize, MaxFileSize } from "../../Types/filetypes";

const SendChannelMessage = gql`
  mutation SendChannelMessage(
    $subject: String!
    $apiKey: String!
    $to: String!
    $from: String!
    $type: String!
    $text: String
    $contentType: String
    $contentRawType: String
    $contentId: String
    $contentName: String
  ) {
    sendChannelMessage(
      subject: $subject
      apiKey: $apiKey
      to: $to
      from: $from
      type: $type
      text: $text
      contentType: $contentType
      contentRawType: $contentRawType
      contentId: $contentId
      contentName: $contentName
    ) {
      channelId
      id
      to
      from
      type
      status
      contentType
      contentId
      text
      createdAt
    }
  }
`;

const FileInputLabel = styled("label", {
  shouldForwardProp: prop => prop !== "backgroundColor"
})(props => ({
  backgroundColor: props.backgroundColor || "gray",
  color: "white",
  cursor: "pointer",
  padding: 12,
  display: "flex",
  borderRadius: "25px",
  boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24)"
}));

function isImageExt(ext) {
  return ["jpeg", "jpg", "jfif", "pjpeg", "pjp", "png"]
    .map(item => item.toUpperCase())
    .includes(ext);
}

function validateFileSize(fileSize, maxSize) {
  return fileSize <= maxSize;
}

function validateFileNameSize(fileName, maxSize) {
  return fileName <= maxSize;
}

const FileInputButton = ({
  info,
  channel = null,
  accept = "image/png, image/jpeg",
  type = FileType.IMAGE,
  children,
  backgroundColor,
  onFileSelected = () => {}
}) => {
  const cancelTokenSource = useRef();
  const id = useRef(uuid());
  const [sendChannelMessage] = useMutation(SendChannelMessage);

  const onSelectedFile = async e => {
    console.log("onSeclectFile", e);
    onFileSelected();
    if (!channel) return;

    const file = e.target.files[0];
    const ext = file?.name?.slice(file.name.lastIndexOf(".") + 1).toUpperCase(); //확장자
    const fId = `${uuid()}.${ext}`;
    console.log(" 파일 정보", file, ext);
    if (type === FileType.IMAGE) {
      if (!isImageExt(ext)) {
        window.parent.postMessage(
          {
            message: "dialog",
            dialogMessage: "Image is invalid. We only support: jpg, jpeg, jfif, pjpeg, pjp, png"
          },
          "*"
        );
        e.target.value = null;
        return;
      }

      if (!validateFileSize(file.size, MaxFileSize.IMAGE)) {
        window.parent.postMessage(
          {
            message: "dialog",
            dialogMessage: "Only images under 5MB can be attached."
          },
          "*"
        );
        e.target.value = null;
        return;
      }
    }

    if (type === FileType.DOCUMENT) {
      if (!validateFileSize(file.size, MaxFileSize.DOCUMENT)) {
        window.parent.postMessage(
          {
            message: "dialog",
            dialogMessage: "Only files under 100MB can be attached."
          },
          "*"
        );
        e.target.value = null;
        return;
      }
    }

    if (!validateFileNameSize(file.name.length, MaxFileNameSize)) {
      window.parent.postMessage(
        {
          message: "dialog",
          dialogMessage:
            "The file name is too long. The letters after the 70 characters are going to be cut off."
        },
        "*"
      );
      e.target.value = null;
      return;
    }

    cancelTokenSource.current = axios.CancelToken.source();
    const ret = await putObject(
      Target.PHOTO,
      "chat-test",
      fId,
      { src: { file }, type: file.type },
      null,
      cancelTokenSource.current?.token
    );

    if (!ret) {
      /* ok */
      const variables = {
        subject: info.subject,
        apiKey: info.apiKey,
        channelId: channel.id,
        to: channel.from,
        from: channel.to,
        type: channel.type,
        contentType: type,
        contentRawType: file.type,
        contentId: fId,
        contentName: file?.name || "unknown"
      };
      sendChannelMessage({
        variables
      });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <FileInputLabel htmlFor={id.current} backgroundColor={backgroundColor}>
        {children}
      </FileInputLabel>
      <input id={id.current} type="file" accept={accept} hidden onChange={onSelectedFile} />
    </div>
  );
};

export default FileInputButton;
