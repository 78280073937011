import { styled } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import MenucloseIcon from "../../../../../../../Icons/MenucloseIcon";
import IconButton from "../../../../../../../IconButton";

const RootDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: 8
});

function Header({ onClose = () => {} }) {
  return (
    <RootDiv>
      <IconButton color={"rgba(0,0,0,0.54)"} onClick={onClose}>
        <MenucloseIcon />
      </IconButton>
      <Typography variant="h3" margin="0px 4px">
        New Conversation
      </Typography>
    </RootDiv>
  );
}

export default Header;
