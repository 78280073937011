import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { default as React } from "react";
import IconButton from "../../../../../../../IconButton";
import DocumentIcon from "../../../../../../../Icons/DocumentIcon";
import DownloadIcon from "../../../../../../../Icons/DownloadIcon";

function convertFileType(type) {
  switch (type) {
    case "audio/aac":
      return "aac";
    case "application/pdf":
      return "pdf";
    case "application/vnd.ms-powerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx";
    case "image/svg+xml":
      return "svg";
    case "text/plain":
      return "text";
    case "audio/webm":
      return "weba";
    case "video/webm":
      return "webm";
    case "video/ogg":
      return "ogv";
    case "audio/ogg":
      return "oga";
    case "video/mpeg":
      return "mpeg";
    case "video/mp4":
      return "mp4";
    case "audio/mpeg":
      return "mp3";
    case "image/png":
      return "png";
    case "image/jpeg":
      return "jpeg";
    case "image/gif":
      return "gif";
    case "text/csv":
      return "csv";
    case "application/msword":
      return "doc";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    default:
      return "unknown";
  }
}

async function get(path, name = "") {
  const init = await fetch(path, { method: "get" });
  const blob = await init.blob();
  // use blob ... (await 키워드 사용)
  // *** 예제: 함수가 실행되면 파일 다운로드 바로 되게 ***
  // 파일이름 가져오기
  // const disposition = init.headers.get("content-disposition");
  // let fileName;
  // if (disposition && disposition.indexOf("attachment") !== -1) {
  //   const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //   const matches = filenameRegex.exec(disposition);
  //   if (matches != null && matches[1]) {
  //     fileName = matches[1].replace(/['"]/g, "");
  //   }
  // }

  // 가상 링크 DOM 만들어서 다운로드 실행
  const url = URL.createObjectURL(await blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

const RootDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "white"
});

function MessageHeaderDocument({ type, url, name, ...others }) {
  return (
    <RootDiv {...others}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.38)"
        }}
      >
        <DocumentIcon />
        <Typography color="inherit" padding="0 4px">{`[${convertFileType(
          type
        )}]`}</Typography>
      </div>
      <div>
        {url && (
          <IconButton>
            <DownloadIcon
              onClick={() => {
                get(url, name);
              }}
            />
          </IconButton>
        )}
      </div>
    </RootDiv>
  );
}
export default MessageHeaderDocument;
