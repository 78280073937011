import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import CleverInfo from "../../../../../ClerverInfo";
import { useIntl } from "react-intl";

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  padding: `28px 32px`,
  alignItems: "center",
  backgroundColor: theme.palette.background.header,
  color: "white"
}));

function ChannelMessageHeader({ title = "undefind", hospitalId }) {
  const intl = useIntl();
  const rtl = intl.locale === "ar";

  return (
    <RootDiv>
      <Typography
        variant="h4"
        color="inherit"
        margin={rtl ? "0 0 0 8px" : "0 8px 0 0"}
      >
        {title}
      </Typography>
      <CleverInfo phoneNumber={title} hospitalId={hospitalId} />
    </RootDiv>
  );
}

export default ChannelMessageHeader;
