import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getObject } from "../../../../../../../../utils/object";
import AudioPlayer from "../../../../../../../AudioPlayer";

function MessageAudio({ message, me }) {
  const [url, setUrl] = useState("");
  const cancelRef = useRef();

  useEffect(() => {
    cancelRef.current = axios.CancelToken.source();

    async function func() {
      getObject(
        "photo",
        "chat-test",
        message.contentId,
        undefined,
        cancelRef.current.token
      )
        .then(res => {
          cancelRef.current = null;
          setUrl(res);
        })
        .catch(e => {
          console.log(e);
        });
    }
    func();

    return () => {
      if (cancelRef.current) {
        cancelRef.current.cancel();
      }
    };
  }, [message?.contentId]);

  return <AudioPlayer url={url} color={me ? "primary" : "secondary"} />;
}

export default MessageAudio;
