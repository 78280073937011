import * as moment from "moment";
import * as moment_tz from "moment-timezone";

export const getMomentLocale = lang => moment.locale(lang);

export const getMomentFormatTimestamp = (timestamp, format = "L") => moment(timestamp, format);

export const getLocalTime = (format = "L") => moment().format(format);

export const getLocalTimeFromTimestamp = (timestamp, format = "L") =>
  moment.unix(timestamp).format(format);
