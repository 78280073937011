import { useIntl } from "react-intl";
import { useCallback } from "react";

// VT Style에 있는 findArabic과는 반대 일을 한다. 원래 이게 맞는 함수
const ARABIC_REGEXP =
  /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/;
export const useFindArabic = props => {
  const intl = useIntl();
  const findArabic = useCallback(
    children =>
      (typeof children === "object" || typeof children === "string") &&
      intl?.locale === "ar" &&
      Boolean(ARABIC_REGEXP.test(children)),
    [intl]
  );
  return { findArabic };
};
