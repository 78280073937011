import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  split
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React, { useMemo } from "react";

const uri = process.env.REACT_APP_CF_GRAPHQL_URL;
const wss = process.env.REACT_APP_CF_WS_URL;

// const uri = "http://localhost:3002/graphql";
// const wss = "ws://localhost:3002/graphql";

function ChatProvider({ token, children }) {
  const client = useMemo(() => {
    const httpLink = new createHttpLink({
      uri: operation => {
        const context = operation.getContext();
        const { endPoint } = context;

        if (endPoint === "clever") {
          //return "https://cvgraph.develop.vsmart00.com/graphql"
          return process.env.REACT_APP_CF_GRAPHQL_CLEVER_URL;
        }

        return uri;
      }
    });

    const timeStartLink = new ApolloLink((operation, forward) => {
      const header = token ? `Bearer ${token}` : null;

      operation.setContext({
        headers: {
          authorization: header
        }
      });

      return forward(operation);
    });

    const wsLink = new WebSocketLink({
      uri: wss,
      options: {
        reconnect: true
      }
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    return new ApolloClient(
      {
        link: from([timeStartLink, splitLink]),
        uri: uri,
        cache: new InMemoryCache({
          addTypename: false,
          typePolicies: {
            Query: {
              fields: {
                queryChannelPagination: {
                  keyArgs: false,
                  merge(existing = {}, incoming) {
                    console.log(existing, incoming);
                    return incoming;
                  }
                },
                queryChannelMessagePagination: {
                  keyArgs: false,
                  merge(existing = {}, incoming, a) {
                    console.log(existing, incoming, a);
                    return incoming;
                  }
                },
                searchPatientPagination: {
                  keyArgs: false,
                  merge(existing = {}, incoming) {
                    console.log(existing, incoming);
                    return incoming;
                  }
                }
              }
            }
          }
        })
      },
      []
    );
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ChatProvider;
