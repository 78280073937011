export const FileType = {
  IMAGE: "image",
  DOCUMENT: "document"
};

export const MaxFileSize = {
  IMAGE: 5 * 1024 * 1024,
  DOCUMENT: 100 * 1024 * 1024,
  AUDIO: 16 * 1024 * 1024,
  VIDEO: 16 * 1024 * 1024
};

export const MaxFileNameSize = 70;
