import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef, useState, forwardRef, useEffect } from "react";
import { v4 as uuid } from "uuid";
import IconButton from "../IconButton";
import DownloadIcon from "../Icons/DownloadIcon";
import PlayIcon from "../Icons/PlayIcon";
import Pause2Icon from "../Icons/Pause2Icon";

const height = 8;
const borderRadius = 8;
const btnSize = 16;

const Input = styled("input", {
  shouldForwardProp: prop => prop !== "color"
})(({ color }) => ({
  margin: "0 8px",
  "-webkitAppearance": "none",
  height: height,
  background:
    color === "primary"
      ? "linear-gradient(to right, #00bfa5, #00bfa5), #777"
      : "linear-gradient(to right, #777, #777), #AAA",
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",

  borderRadius: borderRadius,
  "&::-webkit-slider-thumb": {
    "-webkitAppearance": "none",
    width: btnSize,
    height: btnSize,
    cursor: "pointer",
    // zIndex: 3,
    backgroundColor: color === "primary" ? "#00bfa5" : "gray",
    borderRadius: borderRadius
  }
}));

const InputRange = forwardRef(({ type, ...others }, ref) => {
  useEffect(() => {
    ref.current.addEventListener("input", function (e) {
      ref.current.style.backgroundSize = `${
        (ref.current.value * 100) / ref.current.max
      }%`;
    });
  }, [ref]);

  useEffect(() => {
    if (ref?.current?.value) {
      ref.current.style.backgroundSize = `${
        (ref.current.value * 100) / ref.current.max
      }%`;
    }
  }, [ref, ref?.current?.value]);
  return <Input type="range" {...others} ref={ref} />;
});

async function get(path, name) {
  const init = await fetch(path, { method: "get" });
  const blob = await init.blob();
  // use blob ... (await 키워드 사용)
  // *** 예제: 함수가 실행되면 파일 다운로드 바로 되게 ***
  // 파일이름 가져오기
  const disposition = init.headers.get("content-disposition");
  let fileName = name + ".ogg";
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }

  // 가상 링크 DOM 만들어서 다운로드 실행
  const url = URL.createObjectURL(await blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

function AudioPlayer({ url, download = true, color = "primary" }) {
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef();
  const progressBar = useRef();
  const animationRef = useRef();

  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioRef.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioRef.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioRef.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value * 100) / duration}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  // const backThirty = () => {
  //   progressBar.current.value = Number(progressBar.current.value - 30);
  //   changeRange();
  // };

  // const forwardThirty = () => {
  //   progressBar.current.value = Number(progressBar.current.value + 30);
  //   changeRange();
  // };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <audio
        controls={false}
        style={{ display: "none" }}
        ref={audioRef}
        src={url}
        // controls
        preload="metadata"
        onLoadedData={async v => {
          if (!audioRef.current) return;

          while (audioRef.current?.duration === Infinity) {
            await new Promise(r => setTimeout(r, 1000));
            if (audioRef.current)
              audioRef.current.currentTime = 10000000 * Math.random();
          }

          if (!audioRef.current) return;
          audioRef.current.currentTime = 0;

          const seconds = Math.floor(audioRef.current.duration);
          progressBar.current.max = seconds;
          setDuration(audioRef.current.duration);
          console.log("ASDFASDFASDFASDFASDF");
        }}
        onEnded={() => {
          cancelAnimationFrame(animationRef.current);
          setIsPlaying(false);
        }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ display: "flex", cursor: "pointer", color: "inherit" }}
          onClick={togglePlayPause}
        >
          {isPlaying ? <Pause2Icon /> : <PlayIcon />}
        </div>
        <InputRange
          color={color}
          ref={progressBar}
          onChange={e => {
            changeRange();
          }}
        />
        <Typography color="inherit" style={{ width: 40 }}>
          {calculateTime(currentTime)}
        </Typography>
        <Typography color="inherit" style={{ width: 50, whiteSpace: "nowrap" }}>
          {`/ ${calculateTime(parseInt(Math.floor(duration)))}`}
        </Typography>
        {download && (
          <IconButton
            style={{ color: "inherit" }}
            onClick={() => {
              get(url, uuid());
            }}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default AudioPlayer;
