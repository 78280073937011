import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getObject } from "../../../../../../../../utils/object";
import { styled } from "@mui/system";

const RootDiv = styled("div")({
  display: "flex",
  borderRadius: 8,
  overflow: "hidden"
});

function image_popup(url) {
  var imgObj = new Image();
  imgObj.src = url;

  imgObj.onload = () => {
    const imageWin = window.open(
      "",
      "_blank",
      "width=" + imgObj.width + "px, height=" + imgObj.height + "px"
    );
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write(
      "<a href=javascript:window.close()><img src='" +
        imgObj.src +
        "' border=0></a>"
    );
    imageWin.document.write("</body><html>");
    imageWin.document.title = imgObj.src;
  };
}

function MessageImage({ message }) {
  const [image, setImage] = useState("");
  const cancelRef = useRef();

  useEffect(() => {
    async function test() {
      cancelRef.current = axios.CancelToken.source();
      getObject(
        "photo",
        "chat-test",
        message.contentId,
        undefined,
        cancelRef.current.token
      )
        .then(res => {
          cancelRef.current = null;
          console.log(cancelRef.current);
          setImage(res);
        })
        .catch(e => {
          console.log(e);
        });
    }
    test();

    return () => {
      if (cancelRef.current) {
        cancelRef.current.cancel();
      }
    };
  }, [message?.contentId]);

  return (
    <RootDiv>
      <img
        src={image}
        width={200}
        alt={"loading..."}
        onClick={() => {
          if (image) {
            window.open(image,'_blank')
          }
        }}
      />
    </RootDiv>
  );
}

export default MessageImage;
