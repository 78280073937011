import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import AudioPlayer from "../AudioPlayer";
import SquarefilledIcon from "../Icons/SquarefilledIcon";

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px 16px",
  backgroundColor: theme.palette.primary,
  borderRadius: "8px",
  margin: "0 12px",
  width: 290,
  color: "white"
}));

const useRecorder = (init = false) => {
  const [audioURL, setAudioURL] = useState("");
  const [type, setType] = useState();
  const [isRecording, setIsRecording] = useState(init);
  const [recorder, setRecorder] = useState(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const timer = useRef();
  const start = useRef();
  const animationRef = useRef();
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
    const aaa = () => {
      const cur = new Date();
      setCurrentTime((cur - start.current) / 1000);
      animationRef.current = requestAnimationFrame(aaa);
    };

    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          setRecorder(new MediaRecorder(stream));
          // setStream(stream);
        });
        // requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
      start.current = new Date();
      animationRef.current = requestAnimationFrame(aaa);

      // timer.current = setTimeout(() => {
      //   // setIsRecording(false);
      //   console.log(recorder);
      // }, 10000);
    } else {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      console.log(recorder);
      if (recorder?.state === "recording") recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = e => {
      console.log("handleData!!!", e);
      if (!mountRef.current) return;
      const url = URL.createObjectURL(e.data);
      getDuration(url, v => {
        setDuration(v);
      });
      console.log("!!!!!", url);
      setType(e.data.type);
      setAudioURL(url);
    };

    recorder.ondataavailable = handleData;

    return () => {
      mountRef.current = false;
      cancelAnimationFrame(animationRef.current);
      if (recorder?.stream) {
        recorder.stream.getAudioTracks().forEach(track => {
          track.stop();
        });
      }
    };
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
    start.current = new Date();
    console.log("start!!!");
    // animationRef.current = requestAnimationFrame(aaa);
  };

  const stopRecording = () => {
    if (!recorder) return;
    setIsRecording(false);
    setRecorder(null);
    cancelAnimationFrame(animationRef.current);
    recorder.stream.getAudioTracks().forEach(track => {
      track.stop();
    });
  };

  return {
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    duration,
    currentTime,
    type
  };
};

const AudioRecord = forwardRef((props, ref) => {
  const {
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    // duration,
    currentTime,
    type
  } = useRecorder(true);
  useImperativeHandle(ref, () => ({
    get: () => {
      // while (true) {
      //   console.log(audioURL);

      //   if (audioURL) {
      //     console.log(audioURL);
      //     return audioURL;
      //   }
      //   console.log("sleep");
      //   await sleep(2000);
      // }
      return { url: audioURL, type };
    },
    stop: () => {
      stopRecording();
    }
  }));

  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  return (
    <RootDiv>
      {!audioURL && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{ display: "flex", color: "white", cursor: "pointer" }}
            onClick={!isRecording ? startRecording : stopRecording}
          >
            {!isRecording ? "start recording" : <SquarefilledIcon />}
          </div>
          <Typography
            variant={"h5"}
            style={{
              flexGrow: 1,
              textAlign: "center",
              color: "white"
            }}
          >
            {calculateTime(Math.floor(currentTime))}
          </Typography>
        </div>
      )}

      {audioURL && <AudioPlayer url={audioURL} download={false} />}
    </RootDiv>
  );
});

const getDuration = (url, next) => {
  if (!url) {
    next(0);
    return;
  }

  var _player = new Audio(url);
  _player.addEventListener(
    "durationchange",
    function (e) {
      if (this.duration !== Infinity) {
        var duration = this.duration;
        _player.remove();
        next(duration);
      }
    },
    false
  );
  _player.load();
  _player.currentTime = 24 * 60 * 60; //fake big time
  _player.volume = 0;
  _player.play();
  //waiting...
};

export default AudioRecord;
