import moment from "moment";

let original = moment.prototype.format;

(function() {
  moment.updateLocale("en", {
    longDateFormat: {
      LT: "h:mm A",
      LTS: "h:mm:ss A",
      L: "MM/DD/YYYY",
      LMD: "MM/DD",
      LMM: "MM/YYYY",
      // LW: "MM/",
      l: "M/D/YYYY",
      LL: "MMMM Do YYYY",
      ll: "MMM D YYYY",
      LLL: "MMMM Do YYYY LT",
      lll: "MMM D YYYY LT",
      LLLL: "dddd, MMMM Do YYYY LT",
      llll: "ddd, MMM D YYYY LT",
      CALD: "MM/DD [(]ddd[)]"
    },
    meridiemParse: /AM|PM/
  });
})();
if (localStorage.getItem("lang") === "en")
  moment.prototype.format = function(input) {
    if (input === "SW") {
      let _start = this.clone().startOf("week");
      let _end = this.clone().endOf("week");
      let _format = moment.localeData().longDateFormat("LMD");
      return `${original.apply(_start, [_format])}-${original.apply(_end, [
        _format
      ])}`;
    }

    if (input === "LMD" || input === "LMM" || input === "CALD") {
      input = moment.localeData().longDateFormat(input);
    }
    return original.apply(this, [input]);
  };
