import { gql, useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";

const UpdateChannelMessage = gql`
  mutation UpdateChannelMessage(
    $channelId: String!
    $id: String!
    $status: String
  ) {
    updateChannelMessage(channelId: $channelId, id: $id, status: $status) {
      id
    }
  }
`;

const StyledTypography = styled(Typography)({
  alignSelf: "end",
  padding: "0 4px"
});

function ChannelMessageStatus({ message, me, active = false }) {
  const [updateChannelMessage] = useMutation(UpdateChannelMessage);

  useEffect(() => {
    if (active && !me && message.status === "unread") {
      const variables = {
        channelId: message.channelId,
        id: message.id,
        status: "read"
      };
      console.log("!!!!update!!!", variables);
      updateChannelMessage({ variables });
    }
  }, [message, me, updateChannelMessage, active]);

  return (
    <StyledTypography>
      {message.statusMessage ? message.statusMessage : message.status}
    </StyledTypography>
  );
}

export default ChannelMessageStatus;
