import React from "react";
import PersonIcon from "../Icons/PersonIcon";
import { styled } from "@mui/system";

const RootDiv = styled("div")({
  display: "flex",
  padding: ``,
  // height: 40,
  // width: 40,
  // borderRadius: "25px",
  // padding: 8,
  // backgroundColor: "#ececec",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(0, 0, 0, 0.38)"
});

function BlankProfileImage() {
  return (
    <RootDiv>
      <PersonIcon />
    </RootDiv>
  );
}

export default BlankProfileImage;
