import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import CheckedIcon from "../../../../../../../Icons/CheckedIcon";
import WarningIcon from "../../../../../../../Icons/WarningIcon";

const RootDiv = styled("div")({
  position: "absolute",
  bottom: 90,
  left: 0,
  width: "100%",
  backgroundColor: "black",
  opacity: 0.6,
  color: "white",
  padding: 8,
  boxSizing: "border-box",
  display: "flex"
});

function ChannelExpiredMessage() {
  return (
    <RootDiv>
      <WarningIcon style={{ flexShrink: 0, alignSelf: "center", padding: 8 }} />
      <Typography color="white" padding="12px 8px" variant="body1">
        When the clinic starts the conversation, clinics can only send messages
        with the fixed format, 'Template Message'. The clinic and the patient
        can freely chat after the patient replies to the Template Message.
        Please click the message icon below
        <CheckedIcon />
        and send the 'Template Message' to the clinic. (The conversation session
        becomes expired when 24 hours passes after the patients last message. To
        start the conversation again, the clinic has to send a new 'Template
        Message'.)
      </Typography>
      {/* <Typography color="white">
        일반 메시지는 전송을 할 수 없습니다.
      </Typography> */}
    </RootDiv>
  );
}

export default ChannelExpiredMessage;
