import { keyframes, styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import ProgressIcon from "../Icons/ProgressIcon";
import RemoveIcon from "../Icons/RemoveIcon";
import SearchIcon from "../Icons/SearchIcon";
import { useIntl } from "react-intl";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatedBox = styled("div")({
  margin: 2,
  width: 14,
  height: 14,
  border: "3px solid gray",
  borderTop: "3px solid black",
  borderRight: "3px solid black",
  borderRadius: "25px",
  animation: `${spin} 1s infinite`,
  alignSelf: "center"
});

const RootDiv = styled("div")({
  margin: "16px",
  padding: 6,
  display: "flex",
  borderRadius: 8,
  backgroundColor: "#ededed"
});

const Icons = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "0 4px",
  color: "#878787"
}));

const IconButton = styled("div")(({ theme }) => ({
  display: "flex",
  "&:hover": {
    cursor: "pointer"
  },
  color: theme.palette.icon
}));

const SearchField = ({
  value = "",
  onChange,
  loading,
  placeholder = "Search"
}) => {
  const intl = useIntl();
  const rtl = intl.locale === "ar";
  const [focus, setFocus] = useState(false);
  const timeRef = useRef();
  const inputRef = useRef();
  const [waiting, setWaiting] = useState(false);
  // const [text, setText] = useState(value);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  return (
    <RootDiv>
      <Icons>
        {focus ? (
          <ProgressIcon
            style={{ transform: rtl ? "rotate(180deg)" : "none" }}
          />
        ) : (
          <SearchIcon />
        )}
      </Icons>
      <input
        autoFocus
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        placeholder={placeholder}
        style={{
          border: "none",
          outline: "none",
          flexGrow: 1,
          backgroundColor: "inherit"
        }}
        ref={inputRef}
        onChange={e => {
          if (timeRef.current) {
            clearTimeout(timeRef.current);
          }
          setWaiting(true);
          timeRef.current = setTimeout(() => {
            timeRef.current = null;
            setWaiting(false);
            // setText(e.target.value);
            onChange(e.target.value);
          }, 1000);
        }}
      />

      {(loading || waiting) && <RotatedBox />}
      {!loading && !waiting && value && (
        <IconButton
          onClick={() => {
            inputRef.current.value = "";
            // setText("");
            onChange("");
          }}
        >
          <RemoveIcon />
        </IconButton>
      )}
    </RootDiv>
  );
};

export default SearchField;
