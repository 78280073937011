import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import React from "react";

const StyledTypography = styled(Typography)({
  alignSelf: "end",
  padding: "0 4px"
});

function TimeLine({ timestamp }) {
  const time = moment.unix(timestamp).format(
    "LT"
    // "A hh:mm"
  );

  return <StyledTypography>{time}</StyledTypography>;
}

export default TimeLine;
