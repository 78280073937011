const palette = {
  primary: "#00897b",
  select: {
    dark: "#2c89b2",
    main: "#40c4ff",
    light: "#66cfff",
    hover: "#27799e"
  },
  icon: "#01579b",
  text: {
    primary: "#263238",
    secondary: "#546e7a",
    link: "#1e88e5"
  },
  border: {
    main: "#b0bec5",
    active: "#2196f3",
    guide: "#76B5DB",
    button: "#b0bec5"
  },
  background: {
    header: "#00897b",
    text: "#eceff1",
    profile: "#ececec"
  }
};

export default palette;
