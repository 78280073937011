import { keyframes, styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "../../../../../../../IconButton";
import CheckedIcon from "../../../../../../../Icons/CheckedIcon";
import DocumentIcon from "../../../../../../../Icons/DocumentIcon";
import MicIcon from "../../../../../../../Icons/MicIcon";
import PictureIcon from "../../../../../../../Icons/PictureIcon";
import { FileInputButton, Menu, MessageInput, TempleteMessage } from "./components";
import { FileType } from "./Types/filetypes";

const sladeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const sladeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-300px);
  }
`;

const sladeInEG = keyframes`
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const sladeOutEG = keyframes`
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(300px);
  }
`;

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "sladeIn" && prop !== "rtl"
})(props => ({
  display: "flex",
  animation: `${
    props.sladeIn ? (props.rtl ? sladeInEG : sladeIn) : props.rtl ? sladeOutEG : sladeOut
  } 0.5s`,
  height: "100%"
}));

const Hover = styled("div", {
  shouldForwardProp: prop => prop !== "position" && prop !== "rtl"
})(props => ({
  display: "flex",

  "&[tooltip]:hover": {
    position: "relative"
  },
  "&[tooltip]:after": {
    content: "attr(tooltip)",
    position: "absolute",
    top: `-6px`,
    whiteSpace: "nowrap",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#FFFFFF",
    zIndex: 9999,
    padding: "7px 12px",
    width: "auto",
    minWidth: 50,
    maxWidth: 300,
    borderRadius: "5px",
    boxShadow: "0px 0px 3px 1px rgba(50, 50, 50, 0.4)",
    boxSizing: "border-box",
    opacity: 0,
    visibility: "hidden"
  },
  "&[tooltip]:hover:after": {
    position: "absolute",

    left: props.rtl ? "auto" : "44px",
    right: props.rtl ? "44px" : `auto`,
    opacity: 1,
    visibility: "visible"
  }
}));

function InputBar({
  info,
  channel = null,
  onPress = () => {},
  onChange = () => {},
  disabled = false,
  disabledTemplete = false
}) {
  const intl = useIntl();
  const rtl = intl.locale === "ar";

  console.log(intl);
  const [open, setOpen] = useState(false);
  const [openTemplete, setOpenTemplete] = useState(false);
  const [effect, setEffect] = useState(true);
  const menuRef = useRef();

  useEffect(() => {
    setOpenTemplete(false);
  }, [channel]);

  useEffect(() => {
    const onMouseDown = e => {
      const ret = menuRef.current.contains(e.target);
      console.log(ret);
      if (!ret) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("mousedown", onMouseDown);
    }

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, [open]);
  console.log(channel);
  return (
    <RootDiv sladeIn={effect} rtl={rtl}>
      <Menu
        open={open}
        onClick={() => {
          setOpen(!open);
          setOpenTemplete(false);
        }}
        ref={menuRef}
        disabled={disabled}
      >
        <FileInputButton
          info={info}
          channel={channel}
          accept={"image/png, image/jpeg"}
          type={FileType.IMAGE}
          backgroundColor={"#ffab00"}
          onFileSelected={() => {
            setOpen(false);
          }}
        >
          <Hover
            rtl={rtl}
            tooltip={intl.formatMessage({
              id: "image"
            })}
          >
            <PictureIcon />
          </Hover>
        </FileInputButton>

        <FileInputButton
          info={info}
          channel={channel}
          accept={"*"}
          type={FileType.DOCUMENT}
          backgroundColor={"#00bfa5"}
          onFileSelected={() => {
            setOpen(false);
          }}
        >
          <Hover
            rtl={rtl}
            tooltip={intl.formatMessage({
              id: "file"
            })}
          >
            <DocumentIcon />
          </Hover>
        </FileInputButton>
      </Menu>

      <IconButton
        disabled={disabledTemplete}
        style={{ height: "fit-content", alignSelf: "center" }}
        onClick={() => {
          setOpenTemplete(old => !old);
        }}
      >
        <CheckedIcon />
      </IconButton>

      <MessageInput channel={channel} info={info} onPress={onPress} disabled={disabled} />

      <IconButton
        disabled={disabled}
        style={{ margin: 4, height: "fit-content", alignSelf: "center" }}
        onClick={() => {
          setEffect(false);
          setTimeout(() => {
            onChange("audio");
          }, [250]);
        }}
      >
        <MicIcon />
      </IconButton>
      {openTemplete && (
        <TempleteMessage
          disabled={disabled}
          channel={channel}
          info={info}
          onClose={setOpenTemplete}
        />
      )}
    </RootDiv>
  );
}

export default InputBar;
