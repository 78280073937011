import { gql, useMutation } from "@apollo/client";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import IconButton from "../../../../../../../../../IconButton";
import SMSIcon from "../../../../../../../../../Icons/SendIcon";
// import Tooltip from "../../../../../../../Tooltip";

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "disabled"
})(props => ({
  display: "flex",
  margin: "0 8px",
  padding: 4,
  flexGrow: 1,
  // border: `1px solid ${props.disabled ? "#ececec" : "gray"}`,
  borderRadius: 12,
  backgroundColor: "#eceff1"
}));

const Input = styled("input", {
  // shouldForwardProp: prop => prop !== "disabled"
})(props => ({
  flexGrow: 1,
  border: "none",
  outline: "none",
  backgroundColor: "inherit",
  padding: "24px",
  "::placeholder": {
    color: props.disabled ? "white" : "gray"
  }
}));

const SendChannelMessage = gql`
  mutation SendChannelMessage(
    $subject: String!
    $apiKey: String!
    $to: String!
    $from: String!
    $type: String!
    $text: String
    $contentType: String
    $contentId: String
    $contentName: String
  ) {
    sendChannelMessage(
      subject: $subject
      apiKey: $apiKey
      to: $to
      from: $from
      type: $type
      text: $text
      contentType: $contentType
      contentId: $contentId
      contentName: $contentName
    ) {
      channelId
      id
      to
      from
      type
      status
      contentType
      contentId
      text
      createdAt
    }
  }
`;

function MessageInput({ channel, info, onPress, disabled }) {
  const [text, setText] = useState("");
  const ref = useRef(null);
  const [sendChannelMessage, { error, loading }] = useMutation(SendChannelMessage);

  useEffect(() => {
    setText("");
    ref.current.focus();
  }, [channel]);

  // if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;
  const sendMessage = () => {
    if (!channel) return;
    if (!text) return;

    const variables = {
      channelId: channel.id,
      to: channel.from,
      from: channel.to,
      type: channel.type,
      contentType: "text",
      text: text,
      subject: info.subject,
      apiKey: info.apiKey
    };
    console.log(variables);

    sendChannelMessage({ variables });
    setText("");
    onPress();
  };
  const onChangeText = e => setText(e.target.value);

  return (
    <RootDiv disabled={disabled}>
      <Input
        disabled={!channel || loading || disabled}
        ref={ref}
        autoFocus
        value={text}
        onChange={onChangeText}
        onKeyPress={e => {
          if (e.key === "Enter") {
            sendMessage();
          }
        }}
        placeholder="Enter your message"
      />
      {/* <Tooltip tooltip="THIS IS TOOLTIP!!" position={"left"}> */}
      <IconButton disabled={disabled} onClick={sendMessage}>
        <SMSIcon />
      </IconButton>
      {/* </Tooltip> */}
    </RootDiv>
  );
}

export default MessageInput;
