import { gql, useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef } from "react";
import Button from "../../../../../../../../../Button";
import SendIcon from "../../../../../../../../../Icons/SendIcon";

const SendChannelTempletMessage = gql`
  mutation SendChannelTempletMessage(
    $subject: String!
    $to: String!
    $from: String!
    $type: String!
    $apiKey: String!
    $text: String
  ) {
    sendChannelTempletMessage(
      subject: $subject
      to: $to
      from: $from
      type: $type
      apiKey: $apiKey
      text: $text
    ) {
      error
      data {
        id
        to
        from
        channelId
        type
        status
        contentType
        text
        createdAt
      }
    }
  }
`;

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "slideUp"
})({
  position: "absolute",
  backgroundColor: "white",
  bottom: 90,
  left: 0,
  width: "100%",
  boxSizing: "border-box",
  height: 130,
  display: "flex"
});

function TempleteMessage({ onClose = () => {}, channel, info }) {
  const ref = useRef();
  const [sendChannelTempletMessage] = useMutation(SendChannelTempletMessage, {
    onCompleted: data => {
      const error = data.sendChannelTempletMessage?.error;
      console.log(error);
      if (error) alert(error);
      else onClose();
    }
  });

  const text = `Hello, this is ${info.name}.\n${info.name} wants to talk with you about your dental problem.\nWe only can send you messages after you respond to this message.\nSo please respond to us. Thank you.`;

  const sendMessage = () => {
    if (!channel) return;

    const variables = {
      channelId: channel.id,
      to: channel.from,
      from: channel.to,
      type: channel.type,
      contentType: "templete",
      text: text,
      subject: info.subject,
      apiKey: info.apiKey
    };
    console.log(variables);

    if (true) sendChannelTempletMessage({ variables });
  };

  return (
    <RootDiv ref={ref}>
      <div style={{ padding: 24 }}>
        <Typography variant="h4" color="black">
          Request Message
        </Typography>
        <Typography color="black" marginTop={"12px"}>
          {text}
        </Typography>
      </div>
      <div style={{ alignSelf: "center", padding: 24 }}>
        <Button onClick={sendMessage}>
          <Typography color="inherit">Send</Typography>
          <SendIcon />
        </Button>
      </div>
    </RootDiv>
  );
}

export default TempleteMessage;
