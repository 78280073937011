import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef } from "react";
import {
  ChannelMessageAction,
  ChannelMessageBody,
  ChannelMessageHeader
} from "./components";

const RootDiv = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden"
});

const Header = styled("div")(({ theme }) => ({}));

const Body = styled("div")({
  flexGrow: 1,
  overflow: "hidden",
  backgroundColor: "rgba(207, 216, 220, 0.87)"
});

const Action = styled("div")(({ theme }) => ({
  flexShrink: 0,
  backgroundColor: "white",
  height: "90px",
  padding: "16px 0",
  boxSizing: "border-box",
  position: "relative",
  borderTop: `1px solid ${theme.palette.border.main}`
}));

const InitPage = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  backgroundColor: "#b0bec5",
  justifyContent: "center",
  alignItems: "center"
});

function ChannelMessageView({
  selectedChannel = null,
  info,
  disabled = false
}) {
  const scrollRef = useRef(null);

  if (!selectedChannel)
    return (
      <InitPage>
        <Typography variant="h2">Welcome to clever</Typography>
        <Typography padding={"36px"} align={"center"}>
          Explore the convenience of working and chatting with people Dear, you
          are optimized for your computer.
        </Typography>
      </InitPage>
    );

  const messageScrollTop = () => {
    if (scrollRef.current) scrollRef.current.scrollTop();
  };

  return (
    <RootDiv>
      <Header>
        <ChannelMessageHeader title={selectedChannel?.from} hospitalId={info.hospitalId} />
      </Header>

      <Body>
        <ChannelMessageBody channel={selectedChannel} ref={scrollRef} />
      </Body>

      <Action>
        <ChannelMessageAction
          info={info}
          channel={selectedChannel}
          onPress={messageScrollTop}
          disabled={disabled}
        />
      </Action>
    </RootDiv>
  );
}

export default ChannelMessageView;
