import { gql, useQuery } from "@apollo/client";
import { styled } from "@mui/system";
import React from "react";
import { useFindArabic } from "../../locales/utils";

const SEARCH_PATIENT = gql`
  query searchPatient(
    $hospitalId: String!
    $args: InputSearchPatient
    $comparison: String
    $match: String
  ) {
    searchPatient(hospitalId: $hospitalId, args: $args, comparison: $comparison, match: $match) {
      birthDate
      chartNumber
      hospitalId
      id
      name
    }
  }
`;

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "isArabic"
})(props => ({
  padding: "2px 8px",
  borderRadius: "12px",
  backgroundColor: "#dbdbdb",
  color: "#878787",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  direction: props.isArabic ? "rtl" : "ltr",
  textAlign: props.isArabic ? "left" : "right"
}));

const CleverInfo = ({ phoneNumber, hospitalId }) => {
  const { findArabic } = useFindArabic();
  const { loading, error, data } = useQuery(SEARCH_PATIENT, {
    context: { endPoint: "clever" },
    variables: {
      hospitalId: hospitalId,
      args: {
        cellphoneNumberForSearch: phoneNumber
      }
    },
    fetchPolicy: "no-cache"
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error :(</div>;

  const name = data.searchPatient[0]?.name;
  if (!name) return null;

  return <RootDiv isArabic={findArabic(name)}>{name}</RootDiv>;
};

export default CleverInfo;
