import { gql, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import BlankProfileImage from "../../../../../../../BlankProfileImage";
import CleverInfo from "../../../../../../../ClerverInfo";

const GET_CHANNEL = gql`
  query GetChannel($to: String!, $id: String!) {
    getChannel(to: $to, id: $id) {
      to
      id
      from
      type
      lastMessage {
        createdAt
        text
        contentType
        contentId
        contentName
        status
        type
        from
        to
        id
        channelId
      }
      unReadCnt
      createdAt
      updatedAt
    }
  }
`;

const SUBSCRIPTION_CHANNEL_MESSAGE = gql`
  subscription channelMessageUpdated($channelId: String!) {
    channelMessageUpdated(channelId: $channelId) {
      method
      data {
        channelId
        id
        to
        from
        type
        status
        contentType
        contentId
        text
        createdAt
      }
    }
  }
`;

const Count = styled("div")({
  height: 20,
  width: 20,
  borderRadius: 25,
  backgroundColor: "red",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const convertDate = timestamp => {
  if (!timestamp) return "";
  const aaa = moment.unix(timestamp).format("DD/MM");
  const today = moment().format("DD/MM");
  if (today === aaa)
    return moment.unix(timestamp).format(
      "LT"
      // "A hh:mm"
    );
  return aaa;
};

const covertMessage = message => {
  if (!message) return "";
  if (message.contentType === "text") return message.text;
  else if (message.contentType === "document") return message.contentName;
  else return `[${message.contentType}]`;
};

function ChannelDetail({ channel, viewType, hospitalId }) {
  const intl = useIntl();
  const rtl = intl.locale === "ar";
  const { loading, error, data, subscribeToMore, refetch } = useQuery(
    GET_CHANNEL,
    {
      variables: {
        to: channel.to,
        id: channel.id
      }
    }
  );

  useEffect(() => {
    var unSub = null;

    unSub = subscribeToMore({
      document: SUBSCRIPTION_CHANNEL_MESSAGE,
      variables: { channelId: channel.id },
      shouldResubscribe: true,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.channelMessageUpdated;

        if (newFeedItem.method === "created") {
          refetch();
        }
        if (newFeedItem.method === "updated") {
          refetch();
        }
        if (newFeedItem.method === "deleted") {
          refetch();
        }

        return prev;
      }
    });

    return () => {
      if (unSub) unSub();
    };
  }, [subscribeToMore, channel.id, refetch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if (viewType === "unread" && !data.getChannel.unReadCnt) return null;
  if (viewType === "read" && data.getChannel.unReadCnt) return null;

  const lastDate = convertDate(data.getChannel.lastMessage?.createdAt);
  const lattMessage = covertMessage(data.getChannel.lastMessage);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ padding: "24px 16px" }}>
        <BlankProfileImage />
      </div>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          borderBottom: "1px solid #ececec",
          minWidth: 0
        }}
      >
        <div
          style={{
            flexGrow: 1,
            overflow: "hidden",
            alignSelf: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant={"h4"} margin={rtl ? "0 0 0 8px" : "0 8px 0 0"}>
              {channel.from}
            </Typography>
            <CleverInfo phoneNumber={channel.from} hospitalId={hospitalId} />
          </div>
          <div style={{ height: 20, padding: "4px 0" }}>
            <Typography
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                color: "rgba(0, 0, 0, 0.38)"
              }}
            >
              {lattMessage}
            </Typography>
          </div>
        </div>

        <div
          style={{
            whiteSpace: "nowrap",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: "0 12px"
          }}
        >
          <div style={{ height: 24 }}>
            <Typography color={"rgba(0, 0, 0, 0.54)"}>{lastDate}</Typography>
          </div>
          <Count
            style={{
              visibility: data.getChannel.unReadCnt ? "visible" : "hidden"
            }}
          >
            <Typography style={{ color: "inherit" }}>
              {data.getChannel.unReadCnt}
            </Typography>
          </Count>
        </div>
      </div>
    </div>
  );
}

export default ChannelDetail;
