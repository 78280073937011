import React, { useState } from "react";
import { keyframes, styled } from "@mui/system";
import { Header, PatientTable } from "./components";

const sladeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const sladeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-300px);
  }
`;

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "sladeIn"
})(props => ({
  display: "flex",
  flexDirection: "column",
  animation: `${props.sladeIn ? sladeIn : sladeOut} 0.5s`,
  height: "100%",
  backgroundColor: "white"
}));

function SearchPerson({ onClose = () => {}, info = null, onClick = () => {} }) {
  const [effect, setEffect] = useState(true);

  return (
    <RootDiv sladeIn={effect}>
      <div style={{ flexShrink: 0 }}>
        <Header
          onClose={() => {
            setEffect(false);
            setTimeout(() => {
              onClose();
            }, [400]);
          }}
        />
      </div>
      <div style={{ flexGrow: 1, overflow: "hidden" }}>
        <PatientTable
          info={info}
          onSelected={channel => {
            onClick(channel);
            setEffect(false);
            setTimeout(() => {
              onClose();
            }, [400]);
          }}
        />
      </div>
    </RootDiv>
  );
}

export default SearchPerson;
