import { Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { styled } from "@mui/system";
import { getLocalTimeFromTimestamp } from "../../../../../../../../utils/datetime";

const RootDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  padding: 12
});

const LineThrough = styled("div")({
  borderBottom: `1px solid #b0bec5`,
  width: "100%",
  height: 1
});

const StyledTypography = styled(Typography)({
  position: "absolute",
  color: "white",
  backgroundColor: "#b0bec5",
  padding: `0px 20px`,
  borderRadius: "12px"
});

function DayLine({ timestamp }) {
  const time = getLocalTimeFromTimestamp(timestamp, "L");
  const today = moment().format("L");

  const text = time === today ? "Today" : time;

  return (
    <RootDiv>
      <LineThrough />
      <StyledTypography>{text}</StyledTypography>
    </RootDiv>
  );
}

export default DayLine;
