import React, { useEffect, useRef } from "react";

function ObservedDiv({ children, onIntersecting = () => {}, start = false }) {
  const ref = useRef(null);

  useEffect(() => {
    if (!start) return;

    const onIntersect = async ([entry], observer) => {
      if (entry.isIntersecting) {
        onIntersecting();
      }
    };

    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 0
    };
    const Observer = new IntersectionObserver(onIntersect, option);

    if (ref.current) {
      Observer.observe(ref.current);
    }

    return () => {
      Observer.disconnect();
    };
  }, [onIntersecting, start]);

  return <div ref={ref}>{children}</div>;
}

export default ObservedDiv;
