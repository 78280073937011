import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { styled } from "@mui/system";
import moment from "moment";
import {
  default as React,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import ObservedDiv from "../../../../../ObservedDiv";
import {
  DayLine,
  MessageAudio,
  MessageDoc,
  MessageImage,
  MessageStatus,
  MessageText,
  TimeLine,
  MessageTemplete
} from "./components";

const QUERY_CHANNEL_MESSAGE_PAGINATION = gql`
  query QueryChannelMessagePagination(
    $args: InputQueryChannelMessage
    $page: InputPage
    $sort: InputSort
  ) {
    queryChannelMessagePagination(args: $args, page: $page, sort: $sort) {
      data {
        channelId
        id
        to
        from
        status
        statusMessage
        type
        contentType
        contentRawType
        contentId
        contentName
        text
        createdAt
        header {
          imageId
          documentId
          documentType
          documentName
        }
      }
      lastKey
    }
  }
`;

const SUBSCRIPTION_CHANNEL_MESSAGE = gql`
  subscription channelMessageUpdated($channelId: String!) {
    channelMessageUpdated(channelId: $channelId) {
      method
      data {
        channelId
        id
        to
        from
        type
        status
        statusMessage
        contentType
        contentRawType
        contentId
        contentName
        text
        createdAt
        header {
          imageId
          documentId
          documentType
          documentName
        }
      }
    }
  }
`;

const Message = styled("div", {
  shouldForwardProp: prop => prop !== "me"
})(({ theme, me }) => ({
  width: "fit-content",
  maxWidth: "60%",
  backgroundColor: me ? theme.palette.primary : "white",
  color: me ? "white" : "black",
  borderRadius: "8px",
  overflow: "hidden",
  margin: "0 4px",
  position: "relative",
  padding: 8
}));

const LIMIT = 10;

const renderMessage = (message, me) => {
  switch (message.contentType) {
    case "image":
      return <MessageImage message={message} me={me} />;
    case "text":
      return <MessageText message={message} me={me} />;
    case "document":
      return <MessageDoc message={message} me={me} />;
    case "audio":
      return <MessageAudio message={message} me={me} />;
    case "templete":
      return <MessageTemplete message={message} me={me} />;
    default:
      return message.contentType;
  }
};

const ChannelMessageBody = forwardRef(({ channel }, ref) => {
  const fIndexRef = useRef(-1);
  const readRef = useRef({ isFind: false, ref: null });
  const [observe, setObserve] = useState(false);
  const scrollRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollTop: () => {
      scrollRef.current.scrollTop = 0;
    }
  }));

  const {
    loading,
    error,
    data,
    subscribeToMore,
    fetchMore,
    refetch,
    networkStatus
  } = useQuery(QUERY_CHANNEL_MESSAGE_PAGINATION, {
    variables: {
      args: { channelId: channel?.id },
      sort: { indexKey: "seq", order: true },
      page: { limit: LIMIT }
    },
    skip: !channel?.id,
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (channel) {
      refetch();
    }
  }, [channel, refetch]);

  useEffect(() => {
    var unSub = null;
    if (channel) {
      unSub = subscribeToMore({
        document: SUBSCRIPTION_CHANNEL_MESSAGE,
        variables: { channelId: channel?.id },
        shouldResubscribe: true,
        updateQuery: (prev, { subscriptionData }) => {
          console.log("subscriptionData", subscriptionData);
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.channelMessageUpdated;
          // return subscriptionData;
          if (newFeedItem.method === "created") {
            return Object.assign({}, prev, {
              queryChannelMessagePagination: {
                ...prev.queryChannelMessagePagination,
                data: [
                  newFeedItem.data,
                  ...prev.queryChannelMessagePagination.data
                ]
              }
            });
          }

          if (newFeedItem.method === "updated") {
            return Object.assign({}, prev, {
              queryChannelMessagePagination: {
                ...prev.queryChannelMessagePagination,
                data: prev.queryChannelMessagePagination.data.map(message => {
                  if (message.id === newFeedItem.data.id) {
                    return newFeedItem.data;
                  }
                  return message;
                })
              }
            });
          }

          return prev;
        }
      });
    }
    return () => {
      if (unSub) unSub();
    };
  }, [subscribeToMore, channel]);

  const startKey = data?.queryChannelMessagePagination?.lastKey || null;

  const test = useCallback(() => {
    fetchMore({
      variables: {
        page: {
          startKey: startKey,
          limit: LIMIT
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const existingData = prev.queryChannelMessagePagination.data;
        const incomingData = fetchMoreResult.queryChannelMessagePagination.data;

        const incomingLastKey =
          fetchMoreResult.queryChannelMessagePagination.lastKey;

        return {
          queryChannelMessagePagination: {
            data: [...existingData, ...incomingData],
            lastKey: incomingLastKey
          }
        };
      }
    });
  }, [fetchMore, startKey]);

  useEffect(() => {
    if (startKey) {
      if (fIndexRef.current === -1) {
        fIndexRef.current = data.queryChannelMessagePagination.data.findIndex(
          message => {
            if (message.to === channel.to) {
              return message.status === "read";
            }
            return true;
          }
        );

        if (fIndexRef.current === -1) {
          fetchMore({
            variables: {
              page: {
                startKey: startKey,
                limit: LIMIT
              }
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              const existingData = prev.queryChannelMessagePagination.data;
              const incomingData =
                fetchMoreResult.queryChannelMessagePagination.data;

              const incomingLastKey =
                fetchMoreResult.queryChannelMessagePagination.lastKey;

              return {
                queryChannelMessagePagination: {
                  data: [...existingData, ...incomingData],
                  lastKey: incomingLastKey
                }
              };
            }
          });
        } else {
          if (readRef.current.isFind && readRef.current.ref) {
            readRef.current.ref.scrollIntoView({ behavior: "smooth" });
          }
          setObserve(true);
        }
      }
    } else {
      setObserve(true);
    }
  }, [data, startKey, channel, fetchMore]);

  if (!channel) return <p>No selected channel</p>;
  if (networkStatus !== NetworkStatus.fetchMore) {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
  }

  return (
    <div
      ref={scrollRef}
      onScroll={() => {
        if (scrollRef.current.scrollTop > 0) scrollRef.current.scrollTop = 0;
      }}
      style={{
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        overflowY: "auto"
      }}
    >
      {data.queryChannelMessagePagination.data.map((message, index) => {
        const contents = data.queryChannelMessagePagination.data;
        const me = message.from === channel.to;

        // let toDiff = true;
        let timeDiff = true;
        let dayDiff = true;

        if (contents.length - 1 > index) {
          // toDiff = contents[index + 1].to !== message.to;
          dayDiff =
            moment.unix(contents[index + 1].createdAt).format("YYYY/MM/DD") !==
            moment.unix(message.createdAt).format("YYYY/MM/DD");
        }

        if (index > 0) {
          if (contents[index - 1].to === message.to) {
            // if (contents.length - 1 > index) {
            timeDiff =
              moment.unix(contents[index - 1].createdAt).format("A hh:mm") !==
              moment.unix(message.createdAt).format("A hh:mm");
            // }
          }
        }

        return (
          <React.Fragment key={message.id}>
            <div
              style={{
                overflowWrap: "anywhere",
                padding: 12,
                display: "flex",
                flexDirection:
                  message.from === channel.from ? "row" : "row-reverse"
              }}
              ref={ref => {
                if (me) {
                  if (message.status === "read") {
                    readRef.current.isFind = true;
                    return;
                  }
                  if (!readRef.current.isFind) readRef.current.ref = ref;
                }
              }}
            >
              <Message me={me}>{renderMessage(message, me)}</Message>

              {timeDiff && <TimeLine timestamp={message.createdAt}></TimeLine>}

              <MessageStatus message={message} me={me} active={observe} />
            </div>
            {dayDiff && <DayLine timestamp={message.createdAt} />}
          </React.Fragment>
        );
      })}
      {data.queryChannelMessagePagination.lastKey && (
        <ObservedDiv onIntersecting={test} start={observe}>
          <div>Loading...</div>
        </ObservedDiv>
      )}
    </div>
  );
});

export default ChannelMessageBody;
