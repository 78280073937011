import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import SearchField from "../../../SearchField";
import {
  ChannelListHeader,
  ChannelListTable,
  SearchPerson,
  Select
} from "./components";

const RootDiv = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative"
});

const Topbar = styled("div")({
  flexShrink: 0
});

const Toolbar = styled("div")({
  borderBottom: "1px solid #b0bec5",
  flexShrink: 0
});

const Contents = styled("div")({
  flexGrow: 1,
  overflow: "hidden"
});

const NewTab = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%"
});

const QUERY_CHANNEL_PAGINATION = gql`
  query QueryChannelPagination(
    $args: InputQueryChannel
    $page: InputPage
    $sort: InputSort
    $match: String
  ) {
    queryChannelPagination(
      args: $args
      page: $page
      sort: $sort
      match: $match
    ) {
      data {
        type
        from
        to
        id
        updatedAt
        createdAt
        fromLastUpdatedAt
      }
      lastKey
    }
  }
`;

const SUBSCRIPTION_CHANNEL = gql`
  subscription channelUpdated($to: String!) {
    channelUpdated(to: $to) {
      method
      data {
        to
        id
        from
        type
        updatedAt
        createdAt
        fromLastUpdatedAt
      }
    }
  }
`;
const LIMIT = 10;

const ChannelListView = forwardRef(
  (
    {
      onClick = () => {},
      // to = null,
      selectedChannelId = null,
      // type = "whatsapp",
      info,
      initText = ""
    },
    ref
  ) => {
    const [text, setText] = useState(initText);
    const [search, setSearch] = useState(false);
    const [viewType, setViewType] = useState("all");
    const to = info?.to || null;
    const type = info?.type || "whatsapp";
    const hospitalId = info.hospitalId

    const {
      loading,
      error,
      data,
      subscribeToMore,
      fetchMore,
      refetch,
      networkStatus
    } = useQuery(QUERY_CHANNEL_PAGINATION, {
      variables: {
        args: { type, to, from: text || null },
        sort: { indexKey: "updatedAt", order: true },
        page: { limit: LIMIT },
        match: "contains"
      },
      skip: !to,
      notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
      if (initText) setText(initText);
      console.log(initText);
    }, [initText]);
    console.log(text, data, loading, networkStatus);

    const mountRef = useRef(false);
    useEffect(() => {
      if (!mountRef.current) {
        mountRef.current = true;
      } else {
        refetch();
      }
    }, [text, to, refetch]);

    useEffect(() => {
      var unSub;
      if (to) {
        unSub = subscribeToMore({
          document: SUBSCRIPTION_CHANNEL,
          variables: { to: to },
          shouldResubscribe: true,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            const newFeedItem = subscriptionData.data.channelUpdated;
            if (newFeedItem.method === "created") {
              return Object.assign({}, prev, {
                create: "created",
                queryChannelPagination: {
                  ...prev.queryChannelPagination,
                  data: [newFeedItem.data, ...prev.queryChannelPagination.data]
                }
              });
            }
            if (newFeedItem.method === "deleted") {
              return Object.assign({}, prev, {
                queryChannelPagination: {
                  ...prev.queryChannelPagination,
                  data: [...prev.queryChannelPagination.data].filter(
                    channel => channel.id !== newFeedItem.data.id
                  )
                }
              });
            }

            if (newFeedItem.method === "updated") {
              return Object.assign({}, prev, {
                queryChannelPagination: {
                  ...prev.queryChannelPagination,
                  data: [
                    newFeedItem.data,
                    ...prev.queryChannelPagination.data.filter(
                      channel => channel.id !== newFeedItem.data.id
                    )
                  ]
                }
              });
            }
          }
        });
      }
      return () => {
        if (to && unSub) unSub();
      };
    }, [subscribeToMore, to]);

    const startKey = data?.queryChannelPagination?.lastKey || null;

    const onIntersecting = useCallback(() => {
      fetchMore({
        variables: {
          page: {
            startKey: startKey,
            limit: LIMIT
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const existingData = prev.queryChannelPagination.data;
          const incomingData = fetchMoreResult.queryChannelPagination.data;

          const incomingLastKey =
            fetchMoreResult.queryChannelPagination.lastKey;

          return {
            queryChannelPagination: {
              data: [...existingData, ...incomingData],
              lastKey: incomingLastKey
            }
          };
        }
      });
    }, [fetchMore, startKey]);

    if (!to) return <p>No selected Id</p>;
    if (
      networkStatus !== NetworkStatus.fetchMore &&
      networkStatus !== NetworkStatus.refetch
    ) {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
    }

    return (
      <RootDiv>
        <Topbar>
          <ChannelListHeader
            onClick={() => {
              setSearch(true);
            }}
          />
        </Topbar>
        <Toolbar>
          <SearchField
            onChange={setText}
            loading={loading}
            value={text}
            placeholder="Cell No.​"
          />
        </Toolbar>
        <div style={{ padding: "4px 12px 0px 12px" }}>
          <Select
            name="message"
            id="select"
            onChange={value => {
              console.log(value);
              setViewType(value);
            }}
          >
            <Typography value="all" variant="h5" color="inherit">
              All messages
            </Typography>
            <Typography value="unread" variant="h5" color="inherit">
              unread
            </Typography>
            <Typography value="read" variant="h5" color="inherit">
              read
            </Typography>
          </Select>
        </div>
        <Contents>
          <ChannelListTable
            data={data?.queryChannelPagination?.data || []}
            lastKey={data?.queryChannelPagination?.lastKey || null}
            selectedChannelId={selectedChannelId}
            onClick={onClick}
            onIntersecting={onIntersecting}
            ref={ref}
            viewType={viewType}
            hospitalId={hospitalId}
          />
        </Contents>

        {search && (
          <NewTab>
            <SearchPerson
              info={info}
              onClose={() => {
                setSearch(false);
              }}
              onClick={data => {
                onClick(data);
                console.log(data);
                setText(data.from);
              }}
            />
          </NewTab>
        )}
      </RootDiv>
    );
  }
);

export default ChannelListView;
