import { gql, useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import MessageIcon from "../../../../../../../../../Icons/MessageIcon";

const CREATE_CHANNEL = gql`
  mutation CreateChannel($to: String!, $from: String!, $type: String!) {
    createChannel(to: $to, from: $from, type: $type) {
      id
      from
      type
      createdAt
      fromLastUpdatedAt
      to
    }
  }
`;

const RootDiv = styled("div")(({ theme }) => ({
  padding: 12,
  borderBottom: "1px solid #eceff1",
  "&:hover": {
    backgroundColor: theme.palette.select.main,
    cursor: "pointer"
  },
  display: "flex"
}));

function PatientTableRow({ patient, onSelected = () => {}, info }) {
  const [createChannel] = useMutation(CREATE_CHANNEL, {
    onCompleted: data => {
      console.log(data.createChannel.id);
      onSelected(data.createChannel);
    }
  });

  return (
    <RootDiv
      onClick={() => {
        if (!info.to || !patient.cellphoneNumberForSearch) return;

        const variables = {
          to: info.to,
          from: patient.cellphoneNumberForSearch,
          type: "whatsapp"
        };

        createChannel({
          variables
        });
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <Typography
          variant="h4"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginBottom: 4
          }}
        >
          name:{patient.name}
        </Typography>
        <Typography color="rgba(0,0,0,0.54)">
          chartNumber:{patient.chartNumber}
        </Typography>
        <Typography color="rgba(0,0,0,0.54)">
          cellphoneNumber:{patient.cellphoneNumberForSearch}
        </Typography>
      </div>
      <div style={{ flexShrink: 0, alignSelf: "center", color: "#01579b" }}>
        <MessageIcon />
      </div>
    </RootDiv>
  );
}

export default PatientTableRow;
