import { gql, useMutation } from "@apollo/client";
import { keyframes, styled } from "@mui/system";
import axios from "axios";
import React, { useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { putObjectBlob, Target } from "../../../../../../../../utils/object";
import AudioRecorder from "../../../../../../../AudioRecord";
import IconButton from "../../../../../../../IconButton";
import DeleteIcon from "../../../../../../../Icons/DeleteIcon";
import Progressbar from "../../../../../../../Icons/ProgressIcon";

const SendChannelMessage = gql`
  mutation SendChannelMessage(
    $subject: String!
    $apiKey: String!
    $to: String!
    $from: String!
    $type: String!
    $text: String
    $contentType: String
    $contentId: String
    $contentName: String
  ) {
    sendChannelMessage(
      subject: $subject
      apiKey: $apiKey
      to: $to
      from: $from
      type: $type
      text: $text
      contentType: $contentType
      contentId: $contentId
      contentName: $contentName
    ) {
      channelId
      id
      to
      from
      type
      status
      contentType
      contentId
      text
      createdAt
    }
  }
`;

const sladeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const sladeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-300px);
  }
`;

const StyledDiv = styled("div", {
  shouldForwardProp: prop => prop !== "sladeIn"
})(props => ({
  display: "flex",
  justifyContent: "end",
  animation: `${props.sladeIn ? sladeIn : sladeOut} 0.5s`,
  height: "100%"
}));

function ChannelMessageAudioInput({
  info,
  channel = null,
  onPress = () => {},
  onChange = () => {}
}) {
  const [effect, setEffect] = useState(true);
  const ref = useRef();
  const cancelTokenSource = useRef();
  const [sendChannelMessage, { loading, error }] =
    useMutation(SendChannelMessage);
  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  return (
    <StyledDiv sladeIn={effect}>
      <div
        style={{
          display: "flex",
          backgroundColor: "#eceff1",
          borderRadius: "8px",
          padding: "8px 16px"
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <IconButton
            style={{ color: "red" }}
            onClick={() => {
              setEffect(false);
              setTimeout(() => {
                onChange("basic");
              }, [250]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <AudioRecorder ref={ref} />
        <div style={{ alignSelf: "center" }}>
          <IconButton
            onClick={async () => {
              function sleep(ms) {
                return new Promise(r => setTimeout(r, ms));
              }

              ref.current.stop();

              let data;
              while (true) {
                await sleep(100);
                data = ref.current.get();
                if (data.url) break;
              }

              const { url, type } = data;
              const blob = await fetch(url).then(r => r.blob());

              cancelTokenSource.current = axios.CancelToken.source();
              const fId = uuid();
              console.log(blob, url, type);

              const ret = await putObjectBlob(
                Target.PHOTO,
                "chat-test",
                fId,
                blob,
                type,
                () => {},
                cancelTokenSource.current?.token
              );

              console.log(ret);

              if (!ret) {
                /* ok */
                console.log("!!!!");
                const variables = {
                  subject: info.subject,
                  apiKey: info.apiKey,
                  channelId: channel.id,
                  to: channel.from,
                  from: channel.to,
                  type: channel.type,
                  contentType: "audio",
                  contentId: fId,
                  contentName: fId
                };
                setEffect(false);
                setTimeout(() => {
                  onChange(false);
                  onPress();
                }, [250]);

                sendChannelMessage({
                  variables
                });
              }
            }}
          >
            <Progressbar color="#00897b" />
          </IconButton>
        </div>
      </div>
    </StyledDiv>
  );
}

export default ChannelMessageAudioInput;
