import { gql, useQuery } from "@apollo/client";
import { styled } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import ObservedDiv from "../../../../../../../ObservedDiv";
import SearchField from "../../../../../../../SearchField";
import { PatientTableRow } from "./components";

const SEARCH_PATIENT_PAGINATION = gql`
  query SearchPatientPagination(
    $hospitalId: String!
    $args: InputSearchPatient
    $sort: InputSort
    $page: InputPage
    $comparison: String
    $match: String
  ) {
    searchPatientPagination(
      hospitalId: $hospitalId
      args: $args
      sort: $sort
      page: $page
      comparison: $comparison
      match: $match
    ) {
      data {
        birthDate
        chartNumber
        hospitalId
        id
        name
        cellphoneNumberForSearch
      }
      lastKey
    }
  }
`;

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden"
});

const Toolbar = styled("div")({
  borderBottom: "1px solid #b0bec5",
  flexShrink: 0
});

const LIMIT = 10;

function PatientTable({ onSelected = () => {}, info }) {
  const [text, setText] = useState("");
  const { loading, error, data, refetch, fetchMore } = useQuery(
    SEARCH_PATIENT_PAGINATION,
    {
      context: { endPoint: "clever" },
      variables: {
        hospitalId: info.hospitalId,
        args: {
          name: text || null,
          chartNumber: text || null,
          cellphoneNumberForSearch: text || null
        },
        sort: { indexKey: "name" },
        match: "contains",
        comparison: "or"
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [text, refetch]);

  const startKey = data?.searchPatientPagination?.lastKey || null;

  const onIntersecting = useCallback(() => {
    fetchMore({
      variables: {
        page: {
          startKey: startKey,
          limit: LIMIT
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const existingData = prev.searchPatientPagination.data;
        const incomingData = fetchMoreResult.searchPatientPagination.data;

        const incomingLastKey = fetchMoreResult.searchPatientPagination.lastKey;

        return {
          searchPatientPagination: {
            data: [...existingData, ...incomingData],
            lastKey: incomingLastKey
          }
        };
      }
    });
  }, [fetchMore, startKey]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error :(</div>;

  const lastKey = data?.searchPatientPagination.lastKey;
  const contents =
    data?.searchPatientPagination.data.filter(
      patient => patient.cellphoneNumberForSearch
    ) || [];

  return (
    <RootDiv>
      <Toolbar>
        <SearchField
          onChange={setText}
          loading={loading}
          value={text}
          placeholder="Patient Name, Chart Number, Cell No.​"
        />
      </Toolbar>

      {/* <PatientTable text={text} /> */}

      <div style={{ overflow: "hidden", flexGrow: 1 }}>
        <div style={{ overflowY: "scroll", height: "100%" }}>
          {contents.map((el, index) => {
            return (
              <PatientTableRow
                info={info}
                key={index}
                patient={el}
                onSelected={onSelected}
              />
            );
          })}
          {lastKey && (
            <ObservedDiv onIntersecting={onIntersecting} start={true}>
              <div>Loading...</div>
            </ObservedDiv>
          )}
        </div>
      </div>
    </RootDiv>
  );
}

export default PatientTable;
