import { gql, useSubscription } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AudioInputBar, ChannelExpiredMessage, InputBar } from "./components";

const SUBSCRIPTION_CHANNEL = gql`
  subscription channelUpdated($to: String!) {
    channelUpdated(to: $to) {
      method
      data {
        to
        id
        from
        type
        updatedAt
        createdAt
        fromLastUpdatedAt
      }
    }
  }
`;

const expired = timestamp => {
  if (!timestamp) return true;
  const begin = moment.unix(timestamp);
  const now = moment(new Date());

  const diffHours = now.diff(begin, "hours");
  if (diffHours >= 24) return true;
  return false;
};

const selectBarType = type => {
  if (type === "basic") return InputBar;
  else if (type === "audio") return AudioInputBar;
  // else if (type === "templete") return TempleteMessage;
  return InputBar;
};

function ChannelMessageAction({ channel, info, onPress, disabled }) {
  const [type, setType] = useState("basic");
  const [time, setTime] = useState(channel.fromLastUpdatedAt);
  const Bar = selectBarType(type);
  const inputDisabled = expired(time);

  useSubscription(SUBSCRIPTION_CHANNEL, {
    variables: { to: channel.to },
    onSubscriptionData: ({ subscriptionData }) => {
      const updateAt =
        subscriptionData?.data?.channelUpdated?.data?.fromLastUpdatedAt;
      if (updateAt) setTime(updateAt);
    }
  });

  useEffect(() => {
    if (channel) {
      setTime(channel.fromLastUpdatedAt);
    }
  }, [channel]);

  return (
    <>
      {inputDisabled && <ChannelExpiredMessage />}

      <Bar
        info={info}
        channel={channel}
        onPress={onPress}
        onChange={setType}
        disabled={inputDisabled || disabled}
        disabledTemplete={disabled}
      />
    </>
  );
}

export default ChannelMessageAction;
