import axios from "axios";

const Action = {
  GET: "get",
  PUT: "put",
  DELETE: "delete"
};

const Target = {
  PROFILE: "profile",
  STAMP: "stamp",
  PHOTO: "photo",
  LOGO: "logo",
  DAEMON: "daemon",
  LOGS: "logs",
  FILE: "file",
  AUDIO: "audio"
};

const Method = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST"
};

const uploadUrl = process.env.REACT_APP_CF_UPLOAD_URL;

const requestAction = async (
  action,
  target,
  id,
  name,
  expires,
  cancelToken
) => {
  return new Promise((resolve, reject) => {
    const body = expires
      ? { action, target, id, name, type: null, expires }
      : { action, target, id, name };

    axios({
      url: uploadUrl,
      method: Method.POST,
      data: body,
      headers: { "Content-Type": "application/json" },
      cancelToken
    })
      .then(res => {
        const {
          data,
          data: { statusCode }
        } = res;

        if (statusCode !== 200) return reject(data);

        const { data: url } = JSON.parse(data.body);
        return resolve(url);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getPresignedPostData = request => {
  return new Promise(resolve => {
    axios({
      url: uploadUrl,
      method: Method.POST,
      data: request,
      headers: { "Content-Type": "application/json" }
    }).then(res => {
      resolve(res.data);
    });
  });
};

const getObject = async (target, id, name, _expires, cancelToken) => {
  const expires = _expires ? _expires.toString() : undefined;
  return requestAction(Action.GET, target, id, name, expires, cancelToken);
};

const deleteObject = async (target, id, name) =>
  requestAction(Action.DELETE, target, id, name);

const putObject = async (
  target = "photo",
  id,
  name,
  selectedFile,
  onDownload,
  cancelToken
) => {
  return new Promise(async (resolve, reject) => {
    const { body } = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: selectedFile.type
    });

    const data = JSON.parse(body).data;
    const { fields, _method: method, url } = data;
    const { file } = selectedFile.src;

    const formData = new FormData();
    if (Boolean(fields)) {
      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
    }
    formData.append("file", file);
    console.log(file.type);
    axios({
      url: url,
      method: method,
      headers: { "Content-Type": file.type },
      // headers: { "Content-Type": "audio/mpeg" },
      data: method === "PUT" ? file : formData,
      onUploadProgress: progress => onDownload && onDownload(progress),
      cancelToken
    })
      .then(res => {
        console.log(res);
        if (res.status === 204 || res.status === 200) {
          resolve();
        } else {
          reject(res?.responseText);
        }
      })
      .catch(err => {
        if (err) reject(err);
        resolve();
      });
  });
};

const putObjectBlob = async (
  target,
  id,
  name,
  blob,
  type = "image/png",
  onDownload = () => {},
  cancelToken
) => {
  return new Promise(async (resolve, reject) => {
    const { body } = await getPresignedPostData({
      action: Action.PUT,
      target,
      id,
      name,
      type: type
    });

    const data = JSON.parse(body).data;

    const { fields, _method: method, url } = data;
    const formData = new FormData();

    if (fields) {
      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
    }
    formData.append("file", blob);

    axios({
      url: url,
      headers: { "Content-Type": "audio/mpeg" },
      method: method,
      data: method === "PUT" ? blob : formData,
      onUploadProgress: progress => onDownload && onDownload(progress),
      cancelToken
    })
      .then(res => {
        console.log(res);
        if (res.status === 200 || res.status === 204) {
          resolve();
        } else {
          reject(res?.responseText);
        }
      })
      .catch(err => {
        if (err) reject(err);

        resolve();
      });
  });
};

const uploadDownloadFiles = async (
  files,
  patient,
  hospitalId,
  expires = 24 * 60 * 60 * 7
) => {
  return new Promise(async (resolve, reject) => {
    const body = { target: "download", files, hospitalId, patient, expires };

    axios({
      url: uploadUrl,
      method: Method.POST,
      data: body,
      headers: { "Content-Type": "application/json" }
    }).then(res => {
      const { key, password } = JSON.parse(res.data.body);
      if (key) {
        resolve({
          key: key,
          password: password
        });
      } else {
        reject({
          key: key,
          password: password
        });
      }
    });
  });
};

export {
  requestAction,
  getPresignedPostData,
  getObject,
  putObject,
  putObjectBlob,
  deleteObject,
  uploadDownloadFiles,
  Target
};
