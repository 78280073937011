import { Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const RootDiv = styled("div")({});

function MessageText({ message, me }) {
  return (
    <RootDiv>
      <Typography style={{ color: "inherit" }}>{message.text}</Typography>
    </RootDiv>
  );
}

export default MessageText;
