import { styled } from "@mui/system";
import React, { forwardRef, useEffect, useRef } from "react";
import ObservedDiv from "../../../../../ObservedDiv/ObservedDiv";
import { ChannelDetail } from "./components";

const ChannelMessageRow = styled("div")(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.select.main : "inherit",
  "&:hover": {
    backgroundColor: theme.palette.select.main
  }
}));

const ChannelListTable = forwardRef(
  (
    { data, lastKey, selectedChannelId, onClick, onIntersecting, viewType, hospitalId },
    ref
  ) => {
    const selectedRef = useRef();

    useEffect(() => {
      if (selectedRef.current) {
        selectedRef.current.scrollIntoView();
      }
    }, []);

    return (
      <div style={{ height: "100%", overflowY: "scroll" }}>
        {data.map(channel => (
          <ChannelMessageRow
            ref={divRef => {
              if (selectedChannelId === channel.id) {
                selectedRef.current = divRef;
              }
            }}
            selected={selectedChannelId === channel.id}
            key={channel.id}
            onClick={() => {
              onClick(channel);
            }}
          >
            <ChannelDetail channel={channel} viewType={viewType} hospitalId={hospitalId} />
          </ChannelMessageRow>
        ))}
        {lastKey && (
          <ObservedDiv onIntersecting={onIntersecting} start={true}>
            <div>Loading...</div>
          </ObservedDiv>
        )}
      </div>
    );
  }
);

export default ChannelListTable;
