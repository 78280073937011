import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getObject } from "../../../../../../../../utils/object";
import MessageHeaderDocument from "../MessageHeaderDocument";

const RootDiv = styled("div")({});

function image_popup(url) {
  var imgObj = new Image();
  imgObj.src = url;

  imgObj.onload = () => {
    const imageWin = window.open(
      "",
      "_blank",
      "width=" + imgObj.width + "px, height=" + imgObj.height + "px"
    );
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write(
      "<a href=javascript:window.close()><img src='" +
        imgObj.src +
        "' border=0></a>"
    );
    imageWin.document.write("</body><html>");
    imageWin.document.title = imgObj.src;
  };
}

function MessageTemplete({ message, me }) {
  const [image, setImage] = useState("");
  const [document, setDocument] = useState("");
  const cancelRef = useRef();

  const imageId = message?.header?.imageId;
  const documentId = message?.header?.documentId;

  useEffect(() => {
    async function test(id) {
      cancelRef.current = axios.CancelToken.source();
      getObject("photo", "chat-test", id, undefined, cancelRef.current.token)
        .then(res => {
          cancelRef.current = null;
          console.log(cancelRef.current);
          setImage(res);
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (imageId) test(imageId);

    return () => {
      if (cancelRef.current) {
        cancelRef.current.cancel();
      }
    };
  }, [imageId]);

  useEffect(() => {
    async function test(id) {
      cancelRef.current = axios.CancelToken.source();
      getObject("photo", "chat-test", id, undefined, cancelRef.current.token)
        .then(res => {
          cancelRef.current = null;
          console.log(cancelRef.current);

          setDocument(res);
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (documentId) test(documentId);

    return () => {
      if (cancelRef.current) {
        cancelRef.current.cancel();
      }
    };
  }, [documentId]);
  console.log(message.header);
  return (
    <RootDiv>
      {image && (
        <img
          src={image}
          width={"100%"}
          alt={"loading..."}
          onClick={() => {
            if (image) {
              window.open(image,'_blank')
            }
          }}
        />
      )}
      {document && (
        <MessageHeaderDocument
          style={{ padding: 4 }}
          url={document}
          name={"consult"}
          type={message?.header?.documentType}
        />
      )}
      <Typography style={{ color: "inherit" }}>{message.text}</Typography>
    </RootDiv>
  );
}

export default MessageTemplete;
