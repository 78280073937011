import { styled } from "@mui/system";
import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ChannelListView, ChannelMessageView } from "./components";

const RootDiv = styled("div", {
  shouldForwardProp: prop => prop !== "rtl"
})(({ rtl }) => ({
  display: "flex",
  height: "100%",
  direction: rtl ? "rtl" : "ltr"
}));

const Left = styled("div")({
  width: 400,
  backgroundColor: "white",
  flexShrink: 0
});

const Right = styled("div")({
  minWidth: 600,
  maxWidth: 1000,
  flexGrow: 1
});

const ChatView = forwardRef(
  ({ initChannel = null, type = "whatsapp", info, disabled }, ref) => {
    const [selectedChannel, setSelectedChannel] = useState(initChannel);
    const intl = useIntl();
    const rtl = intl.locale === "ar";

    useEffect(() => {
      if (info?.to) {
        setSelectedChannel(initChannel);
      }
    }, [info?.to, initChannel]);

    if (!info) return <div>loading...</div>;
    console.log(initChannel?.from);
    return (
      <RootDiv rtl={rtl}>
        <Left>
          <ChannelListView
            info={info}
            selectedChannelId={selectedChannel?.id}
            onClick={setSelectedChannel}
            initText={initChannel?.from || ""}
          />
        </Left>
        <Right>
          <ChannelMessageView
            selectedChannel={selectedChannel}
            info={info}
            disabled={disabled}
          />
        </Right>
      </RootDiv>
    );
  }
);

export default ChatView;
