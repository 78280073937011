import { useEffect } from "react";
import ReactDOM from "react-dom";

function Dialog({ children, open = false, onClose = () => {} }) {
  const el = document.createElement("div");
  const elel = document.createElement("div");

  el.style.position = "fixed";
  el.style.top = 0;
  el.style.left = 0;
  el.style.width = "100%";
  el.style.height = "100%";
  el.style.background = "rgba(0, 0, 0, 0.6)";
  el.style.display = `${open ? "block" : "none"}`;

  elel.style.position = "fixed";
  elel.style.background = "white";
  elel.style.maxWidth = "80%";
  elel.style.height = "auto";
  elel.style.top = "50%";
  elel.style.left = "50%";
  elel.style.transform = "translate(-50%,-50%)";
  elel.style.color = "black";
  elel.style.padding = "8px";

  if (open) {
    const onMouseDown = e => {
      const ret = elel.contains(e.target);
      if (!ret) onClose();
    };

    el.addEventListener("mousedown", onMouseDown);
  }

  el.appendChild(elel);

  useEffect(() => {
    const modalElement = document.querySelector("#modal");
    modalElement.appendChild(el);

    return () => {
      modalElement.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, elel);
}

export default Dialog;
