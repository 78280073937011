import { keyframes, styled } from "@mui/system";
import React, { forwardRef, useEffect, useRef, useState } from "react";

const zoomIn = keyframes`
  from {
    transform: scale(0,0);
  }
  to {
    transform: scale(1,1);
  }
`;

const Ripple = styled("span")({
  "&:active": {},
  backgroundColor: "gray",
  animation: `${zoomIn} 0.2s`,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  position: "absolute",
  borderRadius: "4px",
  opacity: 0.3
});

const StyledButton = styled("button")(({ theme }) => ({
  color: theme.palette.icon,
  border: `1px solid ${theme.palette.border.button}`,
  borderRadius: "4px",
  display: `flex`,
  // padding: `4px 8px`,
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "inherit",
  "&:hover": {
    backgroundColor: "#ececec"
  },
  "&:disabled": {
    color: "#ececec"
  },
  position: "relative",
  overflowY: "hidden"
}));

const Button = forwardRef(
  ({ children, onClick = () => {}, ...others }, ref) => {
    const [ripple, setRipple] = useState(false);
    const timer = useRef();

    useEffect(() => {
      return () => {
        if (timer.current) clearTimeout(timer.current);
      };
    }, []);

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { style: { padding: 4 } });
      }
      return child;
    });

    return (
      <StyledButton
        ref={ref}
        onClick={() => {
          setRipple(true);
          timer.current = setTimeout(() => {
            setRipple(false);
          }, [200]);
          onClick();
        }}
        {...others}
      >
        {/* {children} */}
        {childrenWithProps}
        {ripple && <Ripple />}
      </StyledButton>
    );
  }
);

export default Button;
