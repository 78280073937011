import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getObject } from "../../../../../../../../utils/object";
import Dialog from "../../../../../../../Dialog";
import MessageHeaderDocument from "../MessageHeaderDocument";

function MessageDoc({ message }) {
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const cancelRef = useRef();

  useEffect(() => {
    cancelRef.current = axios.CancelToken.source();

    async function test() {
      getObject(
        "photo",
        "chat-test",
        message.contentId,
        undefined,
        cancelRef.current.token
      )
        .then(res => {
          cancelRef.current = null;
          console.log(res);
          setImage(res);
        })
        .catch(e => {
          // console.log(e);
          const {
            // statusCode,
            body
          } = e;
          const { message } = JSON.parse(body);
          console.log(e, message);
        });
    }
    test();

    return () => {
      if (cancelRef.current) {
        cancelRef.current.cancel();
      }
    };
  }, [message?.contentId]);

  return (
    <div
      style={{
        borderRadius: "8px",
        border: "1px solid #b0bec5",
        padding: 12,
        backgroundColor: "white"
      }}
    >
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.38)"
          }}
        >
          <DocumentIcon />
          <Typography color="inherit" padding="0 4px">{`[${convertFileType(
            message.contentRawType
          )}]`}</Typography>
        </div>
        <div>
          {image && (
            <IconButton>
              <DownloadIcon
                onClick={() => {
                  get(image, message.contentName);
                }}
              />
            </IconButton>
          )}
          {error && (
            <DownloadIcon
              onClick={() => {
                setOpen(old => !old);
              }}
            />
          )}
        </div>
      </div> */}
      <MessageHeaderDocument
        url={image}
        type={message.contentRawType}
        name={message.contentName}
      />
      <Typography
        variant="h5"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          padding: "8px 0",
          color: "rgba(0, 0, 0, 0.54)"
          // color: "inherit"
        }}
      >
        {message.contentName}
      </Typography>

      <Dialog open={open} onClose={() => setOpen(false)}>
        Error
      </Dialog>
    </div>
  );
}

export default MessageDoc;
